(function($) {
  'use strict';

  let _defaults = {};


  /**
   * @class
   *
   */
  class CharacterCounter {
    /**
     * Construct CharacterCounter instance
     * @constructor
     * @param {Element} el
     * @param {Object} options
     */
    constructor(el, options) {
      // Display error if el is valid HTML Element
      if (!(el instanceof Element)) {
        console.error(Error(el + ' is not an HTML Element'));
      }
      // If exists, destroy and reinitialize in child
      let ins = CharacterCounter.getInstance(el);
      if (!!ins) {
        ins.destroy();
      }

      this.el = el;
      this.$el = $(el);

      this.el.CharacterCounter = this;

      /**
       * Options for the character counter
       */
      this.options = $.extend({}, CharacterCounter.defaults, options);

      this.isInvalid = false;
      this.isValidLength = false;
      this._setupCounter();
      this._setupEventHandlers();
    }

    static get defaults() {
      return _defaults;
    }

    static init(els, options) {
      let instances = null;
      if (els instanceof Element) {
        instances = new CharacterCounter(els, options);

      } else if (!!els.jquery || els instanceof NodeList) {
        let instancesArr = [];
        for (let i = 0; i < els.length; i++) {
          instancesArr.push(new CharacterCounter(els[i], options));
        }
        instances = instancesArr;
      }

      return instances;
    }

    /**
     * Get Instance
     */
    static getInstance(el) {
      let domElem = !!el.jquery ? el[0] : el;
      return domElem.CharacterCounter;
    }

    /**
     * Teardown component
     */
    destroy() {
      this._removeEventHandlers();
      this.el.CharacterCounter = undefined;
      this._removeCounter();
    }

    /**
     * Setup Event Handlers
     */
    _setupEventHandlers() {
      this._handleUpdateCounterBound = this.updateCounter.bind(this);

      this.el.addEventListener('focus', this._handleUpdateCounterBound, true);
      this.el.addEventListener('input', this._handleUpdateCounterBound, true);
    }

    /**
     * Remove Event Handlers
     */
    _removeEventHandlers() {
      this.el.removeEventListener('focus', this._handleUpdateCounterBound, true);
      this.el.removeEventListener('input', this._handleUpdateCounterBound, true);
    }

    /**
     * Setup counter element
     */
    _setupCounter() {
      this.counterEl = document.createElement('span');
      $(this.counterEl)
        .addClass('character-counter')
        .css({
          float: 'right',
          'font-size': '12px',
          height: 1
        });

      this.$el.parent().append(this.counterEl);
    }

    /**
     * Remove counter element
     */
    _removeCounter() {
      $(this.counterEl).remove();
    }

    /**
     * Update counter
     */
    updateCounter() {
      let maxLength = +this.$el.attr('data-length'),
        actualLength = this.el.value.length;
      this.isValidLength = actualLength <= maxLength;
      let counterString = "";

      if (maxLength) {
        this._validateInput();
        actualLength = this.el.value.length;
        counterString = actualLength + '/' + maxLength;
      }else{
        counterString = actualLength;
      }

      $(this.counterEl).html(counterString);
    }

    /**
     * Add validation classes
     */
    _validateInput() {
      if (this.isValidLength && this.isInvalid) {
        this.isInvalid = false;
        this.$el.removeClass('invalid');
      } else if (!this.isValidLength && !this.isInvalid) {
        this.isInvalid = true;
        this.$el.removeClass('valid');
        this.$el.addClass('invalid');
      }else if(!this.isValidLength){
        this.$el[0].value = this.$el[0].value.substring(0,this.$el.attr('data-length'));
      }
    }
  }

  API.CharacterCounter = CharacterCounter;

  if (API.jQueryLoaded) {
    API.InitializeJqueryWrapper(CharacterCounter, 'characterCounter', 'CharacterCounter');
  }

}(jQuery));
